const validateFile = (file, base64) => {
    const errors = [];
    const sizeLimit = 4000000;
    const allowedExtensionBase64Prefixes = {
        jpg: '/9j',
        jpeg: '/9j',
        png: 'iVB',
        bmp: 'Qk0',
    };

    const hasImageMIMEType = file.type.includes('image/');
    const extension = file.name.slice(file.name.lastIndexOf('.') + 1).toLowerCase();
    const isAllowedExtension = Object.prototype.hasOwnProperty.call(allowedExtensionBase64Prefixes, extension);
    const extensionMatchesBase64 = allowedExtensionBase64Prefixes[extension] === base64.slice(0, 3);
    const isFileTooLarge = file.size > sizeLimit;
    if (!isAllowedExtension
        || !hasImageMIMEType
        || !extensionMatchesBase64) errors.push('Invalid file format');
    if (isFileTooLarge) errors.push('File exceeds maximum size');

    return errors;
};

export default validateFile;
