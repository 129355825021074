import submitFiles from '../submitFiles';
import submitDocRequest from '../submitDocRequest';

const submitFilesAndDocRequest = async (fileList, requestId, accessToken) => {
    const fileListData = await submitFiles(fileList, accessToken);
    if (fileListData?.length > 0) {
        const submitDocumentsReqBody = {
            document_request_id: requestId,
            file_info: [...fileListData],
        };
        return submitDocRequest(submitDocumentsReqBody, requestId, accessToken) || null;
    }
    return null;
};

export default submitFilesAndDocRequest;
