import axios from 'axios';
/* global DD_RUM */

const submitDocRequest = (fileMetaData, documentRequestId, accessToken) => {
    if (documentRequestId === undefined || fileMetaData.length === 0) {
        return null;
    }
    const { REACT_APP_DOCUMENT_EXCHANGE_URL } = process.env;
    const url = `${REACT_APP_DOCUMENT_EXCHANGE_URL}/requests/${documentRequestId}/submissions`;
    const documentSubmissionResp = axios.post(url, fileMetaData, { headers: { Authorization: `Bearer ${accessToken}` } })
        .then(({ status }) => status)
        .catch((err) => {
            DD_RUM.addAction('submitDocumentsError', { err });
            return null;
        });
    return documentSubmissionResp;
};

export default submitDocRequest;
