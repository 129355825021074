/* eslint-disable no-await-in-loop */
/* global DD_RUM */
import axios from 'axios';

const submitFiles = async (fileList, accessToken) => {
    const submissionList = [];
    if (!fileList.length || fileList.length > 15) {
        return submissionList;
    }
    const { REACT_APP_DOCUMENT_EXCHANGE_URL } = process.env;
    const url = `${REACT_APP_DOCUMENT_EXCHANGE_URL}/files`;
    for (let i = 0; i < fileList.length; i++) {
        const req = { data: fileList[i].base64 };
        const submission = await axios.post(url, req, { headers: { Authorization: `Bearer ${accessToken}` } })
            .then((res) => res)
            .catch((err) => {
                DD_RUM.addAction('submitFileListError', { err });
                return { status: 500 };
            });

        const statusCode2XX = /^2\d{2}$/;
        if (statusCode2XX.test(submission.status)) {
            submissionList.push(submission.data);
        } else {
            submissionList.length = 0;
            break;
        }
    }
    return submissionList;
};

export default submitFiles;
