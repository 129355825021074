import React from 'react';
import ReactDom from 'react-dom';
import App from './App';
import { AuthContextProvider } from './utils/cognitoAuth';

const rootElement = document.getElementById('root');
ReactDom.render(
    <AuthContextProvider>
        <App />
    </AuthContextProvider>,
    rootElement,
);
