import React, { useContext, useState } from 'react';
import {
    BrowserRouter as Router, Routes, Route, Navigate,
} from 'react-router-dom';
import {
    themeObj, globals, ThemeProvider, Head,
} from 'tm-library';
import {
    Login, Upload, Complete, TechnicalError,
} from './routes';
import { AuthContext } from './utils/cognitoAuth';
import BrowserNavKeyHandler from './components/BrowserNavKeyHandler';

function App() {
    const { authToken } = useContext(AuthContext);
    const [hasUploaded, setHasUploaded] = useState(false);

    return (
        <ThemeProvider theme={themeObj}>
            <div className={globals}>
                <Head title="Document Exchange" packageName="customer" />
                <Router>
                    <BrowserNavKeyHandler />
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/upload" element={authToken ? <Upload setHasUploaded={setHasUploaded} /> : <Navigate to="/" replace />} />
                        <Route path="/complete" element={hasUploaded ? <Complete hasUploaded={hasUploaded} /> : <Navigate to="/" replace />} />
                        <Route path="/error" element={authToken ? <TechnicalError /> : <Navigate to="/" replace />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </Router>
            </div>
        </ThemeProvider>
    );
}

export default App;
