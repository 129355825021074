/* global DD_RUM */
import React, { useState, useContext } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import {
    Button, Mark, themeObj, SmallBody, Body,
} from 'tm-library';
import { styled } from '@linaria/react';
import {
    Title, Foreground, Subtitle, Copy,
} from '../../components/FormPageTemplate';
import { cognitoAuth, AuthContext, initiateAuth } from '../../utils/cognitoAuth';

const Background = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (min-width: ${themeObj.breakpoints.tablet}px) {
        align-items: center;
    }
`;

const LoginTitle = styled(Title)`
    color: ${themeObj.colors.greys.darkest}
`;

const Label = styled.label`
    display: flex;
    flex-direction: column;
`;
const LabelText = styled(SmallBody)`
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    margin-top: 4px;
`;

const Input = styled.input`
    margin-top:8px;
    border: 2px solid ${themeObj.colors.greys.light};
    height: 45px;
    width: 164px;
    color: #474747;
`;
const InputError = styled(SmallBody)`
    color: ${themeObj.colors.red};
    margin:0;
`;

const FormError = styled(Body)`
    color: ${themeObj.colors.red};
    margin:0;
    text-align:left;
    @media (min-width: ${themeObj.breakpoints.tablet}px) {
        text-align:center;
    }
`;

const LoginButton = styled(Button)`
    margin-top:24px;
    margin-left:0;
    @media (min-width: ${themeObj.breakpoints.tablet}px) {
        margin-left:8px;
    }
`;

const Login = () => {
    const [loginDetails, setLoginDetails] = useState({
        dob: {
            value: '',
            error: '',
        },
        postcode: {
            value: '',
            error: '',
        },
        error: '',
    });
    const { setAuthToken } = useContext(AuthContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [searchParams] = useSearchParams();
    const [requestId] = useState(searchParams.get('rid'));
    const authUser = cognitoAuth(requestId);

    let isFormValid = null;

    const navigate = useNavigate();
    const { search } = useLocation();

    const handleChange = (event) => {
        const { name, value } = event.target;
        const invalidChars = /[^A-Za-z0-9 -]/gi;
        const sanitisedValue = value.replace(invalidChars, '');
        setLoginDetails({
            ...loginDetails,
            [name]: { value: sanitisedValue, error: '' },
        });
    };

    const validateDob = () => {
        let dobError = '';
        if (loginDetails.dob.value === '') {
            dobError = 'Please enter a date of birth';
        }

        const dobAsDate = Date.parse(loginDetails.dob.value);
        if (dobAsDate >= Date.now()) {
            dobError = 'Date of birth cannot be today or a future date';
        }
        return dobError;
    };

    const validatePostcode = () => {
        let postcodeError = '';
        if (loginDetails.postcode.value === '') {
            postcodeError = 'Please enter a postcode';
        }
        return postcodeError;
    };

    const invalidateForm = () => {
        isFormValid = false;
        setIsSubmitting(false);
    };

    const onAuthSuccess = (jwtToken) => {
        setAuthToken(jwtToken);
        setIsSubmitting(false);
        navigate(`/upload${search}`);
    };

    const onAuthFailure = (err) => {
        const newLoginDetails = { ...loginDetails };
        newLoginDetails.error = "Something doesn't look right...";
        invalidateForm();
        DD_RUM.addAction('loginAuthenticationError', { err });
        setLoginDetails(newLoginDetails);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        if (!requestId) {
            onAuthFailure({ location: window?.location, message: 'Missing rid in query parameters' });
        } else if (isFormValid === null) {
            isFormValid = true;
        }

        const dobError = validateDob();
        const postcodeError = validatePostcode();

        if (dobError || postcodeError) {
            const newLoginDetails = { ...loginDetails };
            newLoginDetails.dob.error = dobError;
            newLoginDetails.postcode.error = postcodeError;

            invalidateForm();
            setLoginDetails(newLoginDetails);
        } else if (isFormValid === null) {
            isFormValid = true;
        }

        const [year, month, day] = loginDetails.dob.value.split('-');
        const formattedDob = `${day}${month}${year}`;
        const formattedPostcode = loginDetails.postcode.value.trim().replace(/ |-/g, '').toUpperCase();
        if (isFormValid) {
            initiateAuth(requestId, formattedDob, formattedPostcode, authUser, onAuthSuccess, onAuthFailure);
        } else {
            invalidateForm();
        }
    };

    const date = new Date();
    date.setDate(date.getDate() - 1);
    const [yesterdayISO] = date.toISOString().split('T');

    return (
        <Background>
            <LoginTitle>
                Let&apos;s upload your
                <Mark> documents</Mark>
            </LoginTitle>
            <Foreground>
                <Form onSubmit={handleSubmit}>
                    <Subtitle>
                        Customer
                        <Mark> login</Mark>
                    </Subtitle>
                    {loginDetails.error
                        && (
                            <FormError>
                                Something doesn&apos;t look right...
                                <br />
                                Check that your date of birth and postcode are correct.
                                <br />
                                If they are, then your document upload link may have expired.
                                <br />
                                If you&apos;ve already submitted your documents, you can&apos;t go back in.
                            </FormError>
                        )}
                    <Copy>
                        <strong>Just enter </strong>
                        your
                        <strong> date of birth </strong>
                        and
                        <strong> postcode </strong>
                        to login.
                    </Copy>
                    <Label htmlFor="dob">
                        <LabelText>Date of birth</LabelText>
                        <Input
                            id="dob"
                            onChange={handleChange}
                            onFocus={handleChange}
                            value={loginDetails.dob.value}
                            name="dob"
                            type="date"
                            min="1900-01-01"
                            max={yesterdayISO}
                        />
                        {loginDetails.dob.error && <InputError>{loginDetails.dob.error}</InputError>}
                    </Label>
                    <Label htmlFor="postcode">
                        <LabelText>Postcode</LabelText>
                        <Input
                            id="postcode"
                            onChange={handleChange}
                            value={loginDetails.postcode.value}
                            name="postcode"
                            type="text"
                            maxLength="10"
                        />
                        {loginDetails.postcode.error && <InputError>{loginDetails.postcode.error}</InputError>}
                    </Label>
                    <LoginButton type="submit" isLoading={isSubmitting}>Login</LoginButton>
                </Form>
            </Foreground>
        </Background>
    );
};

export default Login;
