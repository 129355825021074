import React, {
    useState, useRef, useEffect, useContext,
} from 'react';
import {
    Button, themeObj, SmallBody, Mark,
} from 'tm-library';
import { styled } from '@linaria/react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import FileList from '../FileList';
import { FormPageTemplate, Subtitle, Copy } from '../FormPageTemplate';
import submitFilesAndDocRequest from '../../actions/submitFilesAndDocRequest';
import validateFile from '../../actions/validateFile';
import getUniqueListBy from '../../utils/getUniqueListBy';
import { AuthContext } from '../../utils/cognitoAuth';

const FileUploadSection = styled.div`
    background:${themeObj.colors.greys.lightest};
    border-radius:12px;
    padding: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FileRequirimentsCopy = styled(SmallBody)`
    font-size: 12px;
`;
const Error = styled(SmallBody)`
    color: ${themeObj.colors.red};
    margin:0;
`;

// this is due to issue in hmtl with how file input is. Requires a work around in order to style it
const HiddenInput = styled.input`
    display:none;
`;

const FileUpload = ({ setHasUploaded }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [errorList, setErrorList] = useState([]);
    const inputRef = useRef(null);
    const maxFileCount = 15;
    const [searchParams] = useSearchParams();
    const [requestId] = useState(searchParams.get('rid'));
    const { search } = useLocation();

    const navigate = useNavigate();

    const { authToken } = useContext(AuthContext);

    const addFiles = (attachementList) => {
        const newFileList = [...fileList, ...attachementList];
        setFileList(newFileList);
    };

    const removeFileItem = (id) => {
        const newFileList = fileList.filter((file) => id !== file.id);
        const newErrorList = errorList.filter((error) => id !== error.id);
        setFileList(newFileList);
        setErrorList(newErrorList);
    };

    const addFormErrors = (formErrors) => {
        setErrorList([...errorList, ...formErrors]);
    };

    const readFile = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            const base64 = event.target.result.replace(/(data:)\w+\/\w+(;base64,)/, '');
            resolve({ file, base64 });
        };
        reader.onerror = reject;
    });

    const handleFileAttach = (files) => {
        const filePromises = files.map((file) => readFile(file));

        Promise.all(filePromises)
            .then((attachedFiles) => {
                const formErrors = [];
                const modelledFiles = [];
                const uniqueFileList = getUniqueListBy(attachedFiles, 'name');

                const remainingListCapacity = maxFileCount - fileList.length;
                const listWithinCapacity = uniqueFileList.slice(0, remainingListCapacity);

                listWithinCapacity.forEach(({ file, base64 }) => {
                    const { name, size } = file;
                    const fileId = `file-${name}`;
                    const fileErrors = validateFile(file, base64);
                    const errorItem = {
                        id: fileId,
                        errors: fileErrors,
                    };
                    const newFile = {
                        id: fileId,
                        size,
                        name,
                        base64,
                        errors: fileErrors,
                    };

                    if (fileErrors.length > 0) formErrors.push(errorItem);
                    modelledFiles.push(newFile);
                });
                addFiles(modelledFiles);
                addFormErrors(formErrors);
            });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);
        const submissionResponse = await submitFilesAndDocRequest(fileList, requestId, authToken);

        const statusCode2XX = /^2\d{2}$/;
        setIsSubmitting(false);
        if (statusCode2XX.test(submissionResponse)) {
            setHasUploaded(true);
            navigate('/complete', { state: { path: 'upload' } });
        } else {
            navigate(`/error${search}`);
        }
    };

    useEffect(() => {
        if (fileList.length < 1 || errorList.length > 0) {
            setIsFormValid(false);
        } else {
            setIsFormValid(true);
        }
    }, [fileList, errorList]);

    return (
        <FormPageTemplate pageTitle="Upload in one simple step">
            <form onSubmit={handleSubmit}>
                <Subtitle>
                    Choose your
                    <Mark> files</Mark>
                </Subtitle>
                <Copy>
                    Make sure your files are good quality and aren&apos;t cropped. Upload all the files you need to, as you won&apos;t be able to come back. Remember, you can upload a maximum of
                    {` ${maxFileCount} `}
                    documents.
                </Copy>
                <FileUploadSection>
                    <FileRequirimentsCopy>
                        Accepted file types:
                        <br />
                        <strong> JPG, JPEG, BMP, PNG.</strong>
                        <br />
                        Maximum upload file size is
                        <strong> 4MB.</strong>
                    </FileRequirimentsCopy>
                    {fileList.length > 0
                        && <FileList fileList={fileList} removeFile={removeFileItem} />}
                    {fileList.length < maxFileCount && (
                        <>
                            <Button
                                variant="secondary"
                                onClick={(event) => {
                                    event.preventDefault();
                                    const input = inputRef.current;
                                    if (input) {
                                        input.value = '';
                                        input.click();
                                    }
                                }}
                            >
                                add files
                            </Button>
                            <HiddenInput
                                type="file"
                                ref={inputRef}
                                multiple
                                onChange={(event) => {
                                    const files = event.target?.files;
                                    if (files?.length > 0) {
                                        const filesArr = Array.from(files);
                                        handleFileAttach(filesArr);
                                    }
                                }}
                            />
                        </>
                    )}
                    {isFormValid
                        && (
                            <Button
                                type="submit"
                                isLoading={isSubmitting}
                            >
                                Submit
                            </Button>
                        )}
                    {errorList.length > 0 && <Error>Errors found! Please review</Error>}
                </FileUploadSection>
            </form>
        </FormPageTemplate>
    );
};

export default FileUpload;
