import React from 'react';
import { styled } from '@linaria/react';
import { H2, Button, themeObj } from 'tm-library/src';
import { useNavigate, useLocation } from 'react-router-dom';
import { Error as ErrorIcon } from 'tm-library/src/components/Icons';
import PageTemplate from '../../components/FormPageTemplate';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 17%;
    @media (min-width: ${themeObj.breakpoints.tablet}px) {
        align-items: center;
        width: 500px;
    }
`;

const Error = styled(H2)`
    color: ${themeObj.colors.red};
    margin-bottom: 64px;
    @media (min-width: ${themeObj.breakpoints.tablet}px) {
        margin-bottom: 32px;
    }
`;

const TryAgainButton = styled(Button)`
    margin-left:0;
    @media (min-width: ${themeObj.breakpoints.tablet}px) {
        margin-left:8px;
    }
`;
const TechnicalError = () => {
    const navigate = useNavigate();
    const { search } = useLocation();

    return (
        <PageTemplate pageTitle="There's a problem">
            <Content>
                <ErrorIcon />
                <Error>Sorry, that didn&apos;t work.</Error>
                <TryAgainButton onClick={() => navigate(`/${search}`)}>Please try again</TryAgainButton>
            </Content>
        </PageTemplate>
    );
};

export default TechnicalError;
