import React from 'react';
import {
    themeObj, Cross, ValidationError, SmallBody,
} from 'tm-library';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

const StyledFileList = styled.ul`
    list-style: none;
    padding: 0;
    max-width:350px;

`;
const FileItem = styled.div`
    background: ${themeObj.colors.white};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07);
    max-height: 40px;
    width:250px;
    margin: 6px auto;
    padding: 12px;
    padding-left: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &.error {
        border-color: ${themeObj.colors.red};
        border-width: 1px;
        border-style: solid;
        border-left-width: 8px;
    }
`;

const FileDetails = styled(SmallBody)`
    color: ${themeObj.colors.primary}
    margin:0;
    display: flex;
    align-items: center;
    svg{
        margin-right:8px;
    }
`;

const Error = styled(SmallBody)`
    color: ${themeObj.colors.red};
    font-size: 12px;
    margin:0;
    text-align: left;
`;

const RemoveFile = styled(Cross)`
    width:12px;
    height:12px;
`;

const FileList = ({ fileList, removeFile }) => {
    const formatFileSize = (size) => {
        const sizeKB = size / 1000;
        let formattedSize;
        if (sizeKB > 1000) {
            const sizeMB = sizeKB / 1000;
            formattedSize = `${Math.round(sizeMB * 10) / 10}MB`;
        } else {
            formattedSize = `${Math.floor(sizeKB)}KB`;
        }
        return formattedSize;
    };

    const truncatedFileName = (fileName) => {
        const maxCharLength = 16;
        const fileExtensionRegex = /\.\w+$/i;
        const extension = fileName.match(fileExtensionRegex)[0];
        const fileNameNoExtension = fileName.replace(extension, '');
        if (fileNameNoExtension.length <= maxCharLength) {
            return fileName;
        }
        const shortName = fileName.slice(0, 16);
        const newFileName = `${shortName}... ${extension}`;
        return newFileName;
    };
    return (
        <StyledFileList>
            {fileList.map((file, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`list-item-${i}`}>
                    <FileItem key={file.id} className={cx(file.errors.length > 0 && 'error')}>
                        <FileDetails>
                            {file.errors.length > 0 && (<ValidationError styles={{ marginRight: 8 }} />)}
                            {` ${truncatedFileName(file.name)} - ${formatFileSize(file.size)}`}
                        </FileDetails>
                        <RemoveFile color="#474747" onClick={() => removeFile(file.id)} role="button" />
                    </FileItem>
                    {file.errors.length > 0 && (
                        file.errors.map((err) => (<Error key={`${file.id}-err-${err.slice(0, 10)}`}>{err}</Error>))
                    )}
                </li>
            ))}
        </StyledFileList>
    );
};

export default FileList;
