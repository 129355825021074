import React, { useEffect } from 'react';
import { useNavigationType, useNavigate, useLocation } from 'react-router-dom';

const BrowserNavKeyHandler = () => {
    const navigationAction = useNavigationType();
    const navigate = useNavigate();
    const { search } = useLocation();
    useEffect(() => () => {
        if (navigationAction === 'PUSH') {
            navigate(`/${search}`);
        }
    }, [navigationAction]);

    return <div />;
};

export default BrowserNavKeyHandler;
