import React from 'react';
import { themeObj, useViewportWidth } from 'tm-library';
import {
    H1, H2, Body,
} from 'tm-library/src/components/Typography';
import { styled } from '@linaria/react';
import coffeeImage from 'Assets/coffee-cup.webp';
import tmCardImage from 'Assets/tm-card.webp';

const Background = styled.div`
    background: linear-gradient(180deg, ${themeObj.colors.primary} 0%, ${themeObj.colors.primaryLight} 100%);
    height:100%;
    width:100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
`;

const CoffeeImage = styled.img`
    position: absolute;
    top: 0;
    right: 0;
`;

const TMCardImage = styled.img`
    position: fixed;
    bottom: 0;
    left: 0;
`;

const Title = styled(H1)`
    color:${themeObj.colors.white};
    margin: 32px auto 48px auto;
    padding: 0 16px;
    text-align:left;
    @media (min-width: ${themeObj.breakpoints.tablet}px) {
        text-align:center;
    }
    
`;

const Foreground = styled.div`
    background:${themeObj.colors.white};
    border-radius:12px;
    padding: 32px;
    margin:16px;
    max-width:500px;
`;

const Subtitle = styled(H2)`
    text-align:left;
    @media (min-width: ${themeObj.breakpoints.tablet}px) {
        text-align:center;
    }
`;

const Copy = styled(Body)`
    margin: 32px auto 40px auto;
    text-align:left;
    @media (min-width: ${themeObj.breakpoints.tablet}px) {
        text-align:center;
    }
`;

const FormPageTemplate = ({
    pageTitle, children, className, style,
}) => {
    const viewportWidth = useViewportWidth();
    return (
        <Background
            className={className}
            style={style}
        >
            {viewportWidth > themeObj.breakpoints.laptop && <CoffeeImage src={coffeeImage} alt="" />}
            <Title>{pageTitle}</Title>
            <Foreground>
                {children}
            </Foreground>
            {viewportWidth > themeObj.breakpoints.laptop && <TMCardImage src={tmCardImage} alt="" />}
        </Background>
    );
};

export default FormPageTemplate;

export {
    FormPageTemplate, Title, Foreground, Subtitle, Copy,
};
