import {
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUser,
} from 'amazon-cognito-identity-js';
import React, {
    createContext, useMemo, useState, useEffect,
} from 'react';

const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState();
    const authState = useMemo(
        () => ({ authToken, setAuthToken }),
        [authToken],
    );
    return (
        <AuthContext.Provider value={authState}>
            {children}
        </AuthContext.Provider>
    );
};

function cognitoAuth(username) {
    if (!username) return null;
    const [authUser, setAuthUser] = useState();

    useEffect(() => {
        const UserPool = new CognitoUserPool({
            UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
            ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
        });
        const userData = {
            Username: username,
            Pool: UserPool,
        };

        const cognitoUser = new CognitoUser(userData);
        setAuthUser(cognitoUser);
    }, [username]);

    return authUser;
}
function initiateAuth(username, dob, postcode, user, onSuccessHandler, onFailure) {
    const authenticationDetails = new AuthenticationDetails({
        Username: username,
    });
    user.setAuthenticationFlowType('CUSTOM_AUTH');
    function customChallenge() {
        const challengeResponses = `${dob}|${postcode}`;
        user.sendCustomChallengeAnswer(challengeResponses, this);
    }

    user.initiateAuth(authenticationDetails, {
        onSuccess: ({ accessToken }) => {
            onSuccessHandler(accessToken.jwtToken);
        },
        onFailure,
        customChallenge,
    });
}

export default cognitoAuth;
export {
    cognitoAuth, initiateAuth, AuthContextProvider, AuthContext,
};
