import { useEffect, useState } from 'react';
import debounce from '../debounce';

export default () => {
    const [viewportWidth, setViewportWidth] = useState(null);

    const onResizeHandler = () => {
        setViewportWidth(window.innerWidth);
    };

    useEffect(() => {
        if (!viewportWidth) setViewportWidth(window.innerWidth);
        window.addEventListener('resize', debounce(onResizeHandler, 100));
        return () => {
            window.removeEventListener(
                'resize',
                debounce(onResizeHandler, 100),
            );
        };
    }, [viewportWidth]);
    return viewportWidth;
};
