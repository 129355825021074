import React, {
    useContext, useEffect,
} from 'react';
import { Mark, SuccessTick } from 'tm-library';
import { styled } from '@linaria/react';
import { FormPageTemplate, Subtitle, Copy } from '../components/FormPageTemplate';
import { AuthContext } from '../utils/cognitoAuth';

const Content = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    padding-bottom: 17%;
`;

const Complete = () => {
    const { authToken, setAuthToken } = useContext(AuthContext);
    useEffect(() => {
        if (authToken) {
            setAuthToken('');
        }
    });

    return (
        <FormPageTemplate pageTitle="You’re all done!">
            <Content>
                <Subtitle>
                    <Mark>
                        Thanks for uploading
                        your documents.
                    </Mark>
                </Subtitle>
                <Copy>
                    <strong>They&apos;ve been submitted </strong>
                    and we&apos;ll be in touch soon.
                </Copy>
                <SuccessTick />
            </Content>
        </FormPageTemplate>
    );
};

export default Complete;
